/************ Custom Font ************/
@import url("https://fonts.googleapis.com/css?family=Delius+Unicase");

@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Book.ttf");
  font-weight: 400;
}

/* Fonts */
:root {
  --font-default: "Open Sans", system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-primary: "Montserrat", sans-serif;
  --font-secondary: "Poppins", sans-serif;
  --font-Heading: "Delius Unicase";
}

/* Colors */
:root {
  --color-default: #222222;
  --color-primary: #00acc1;
  --color-primary-2: #008374;
  /* rgba(35,41,70,var(--tw-bg-opacity)); */
  /* --color-primary: rgba(35, 41, 70);
  --color-primary: #0072c6; */
  --color-primary-1: linear-gradient(62deg, #232946 0%, #00473d 100%);
  --color-secondary: #f85a40;
  /* --color-secondary: #0b192f; */

  /* font color  */
  --font-color-default: whitesmoke;
  --font-color-1: #8ec5fc;

  /* gradient  */
  --rgb-1: 170, 54, 124;
  --rgb-2: 74, 47, 189;
  /* --rgb-1: 170, 54, 124; */
}

/* Smooth scroll behavior */
/* :root {
  scroll-behavior: smooth;
} */

/************ Default CSS ************/
* {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  /* background-color: #121212 !important; */
  background-color: #000 !important;
  color: var(--font-color-default) !important;
  /* font-family: "Centra", sans-serif !important; */
  font-family: var(--font-Heading), sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

@media (min-width: 1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

p.success {
  color: green;
}

p.danger {
  color: red;
}