/************ Contacts Css ************/
.contact {
  background-clip: border-box;
  background: linear-gradient(90.21deg,
      rgba(170, 54, 124, 0.5) -5.91%,
      rgba(74, 47, 189, 0.5) 50.58%);
  padding: 0 0 200px 0;
  font-family: var(--font-default);
}

.contact .heading {
  font-family: var(--font-Heading);
}

.contact .avatar img {
  width: 92%;
}

.contact .contact-icon {
  width: 40px;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.contact .contact-icon::before {
  transform: scale(1);
}

.contact .contact-icon:hover {
  transform: scale(1.3);
}

.contact .contact-text {
  color: var(--color-primary);
  /* font-family: var(--font-secondary); */
  font-weight: bold;
}

.contact h2 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
}

.contact form input,
.contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

.contact form input:focus,
.contact form textarea:focus {
  /* background: rgba(255, 255, 255, 1); */
  /* background: linear-gradient(90.21deg,
      rgba(170, 54, 124, 0.2) -5.91%,
      rgba(74, 47, 189, 0.2) 111.58%); */
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: #fff;
}

.contact form input::placeholder,
.contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.contact form input:focus::placeholder,
.contact form textarea:focus::placeholder {
  /* color: #121212; */
  color: #fff;
  opacity: 0.3;
}

.contact form button {
  font-family: var(--font-Heading);
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 10px;
  position: relative;
  transition: 0.3s ease-in-out;
}

.contact form button span {
  z-index: 1;
  position: relative;
}

.contact form button:hover {
  color: #fff;
}

.contact form button::before {
  border-radius: 8px;
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}

.contact form button:hover::before {
  width: 100%;
}