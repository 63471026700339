/* it also control fliptextnav.jsx file with 'animated_text_title' name  */
/*=====================>  start flip text ==> Mahmud <=============== */
.animated_text_title {
  position: relative;
}

.animated_text_title span {
  position: relative;
  display: inline-block;
  /* font-size: 40px; */
  color: var(--color-primary);
  animation: flipbanner 10s infinite;
  animation-delay: calc(1s * var(--i));
}

@keyframes flipbanner {
  0%,
  80% {
    transform: rotateY(360deg);
  }
}

/*=====================>  end flip text <=============== */
