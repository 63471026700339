/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
}

nav.navbar.scrolled {
  padding: 0px 0;
  /* background-color: rgba(170, 54, 124, 1); */
  /* background-color: #0b192f; */
  background-image: url("../img/footer-bg.png");
  opacity: 0.9;
  /* background: linear-gradient(
    90.21deg,
    rgba(170, 54, 124, 0.5) -5.91%,
    rgba(74, 47, 189, 0.5) 111.58%
  ); */
}

.main_nav .brand {
  color: var(--font-color-1);
}

nav.navbar a.navbar-brand {
  width: 9%;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}

.navbar-text .item {
  width: 50%;
}

Nav .active {
  background-color: var(--color-primary);
  border-radius: 25px;
  /* margin: 0 50px; */
  width: auto;
}

/* nav.navbar .navbar-nav a.nav-link.navbar-link:hover, */
/* nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
  border: 1px solid #fff;
  background-color: transparent;
  transition: 0.3s ease-in-out;
  border-radius: 15px;
} */
span.navbar-text {
  display: flex;
  align-items: center;
}

.navbar-text button {
  font-weight: 700;
  color: #fff;
  padding: 5px 20px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}

.navbar-text button span {
  z-index: 1;
}

.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
  border-radius: 15px;
}

.navbar-text button:hover {
  color: #121212;
}

.navbar-text button:hover::before {
  width: 100%;
  height: 100%;
  position: absolute;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
  width: auto;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}

nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}