/************ Skills CSS ************/
.skill {
  background-clip: border-box;
  background: linear-gradient(90.21deg,
      rgba(170, 54, 124, 0.5) -5.91%,
      rgba(74, 47, 189, 0.5) 111.58%);
}

.skill-bx {
  text-align: center;
  align-items: center;
  align-content: center;
  padding: 60px 0px;
  z-index: -1;
}

.skill h2 {
  font-size: 45px;
  font-weight: 700;
}

.skill p {
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 75px 0;
}

/* .skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}
.skill-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
} */
/* .background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
} */

/******** framework design ************************** */
.framework .center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.framework .card1 {
  /* position: fixed; */
  border-radius: 12px;
  width: 110px;
  height: 135px;
  text-align: center;
  margin: 10px;
  background: #ffffff;
  cursor: pointer;
  transition: all 0.3s;
}

.framework .card1 {
  z-index: 2;
}

.framework .heading {
  font-size: 24px;
  font-weight: 400;
  /* color: var(--main-text-color); */
}

.framework .heading:after {
  display: block;
  height: 3px;
  background-color: aqua;
  content: "";
  width: 70px;
  margin: 5px auto 10px;
}

.framework p {
  font-size: 14px;
  width: 100%;
  color: black;
}

.card1:hover {
  /* box-shadow: 0 2px 10px white green; */
  transform: scale(1.2);
}

.framework img {
  padding-top: 10px;
  padding-bottom: 5px;
  /* margin-bottom: 10px; */
  width: auto;
  height: 80px;
}